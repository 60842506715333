import { Box, CircularProgress, TextField, Button, FormControlLabel, Link, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Formik } from "formik";
import style from './style.module.scss'
import dreacaLogo from '../../assets/images/logo.png'
import dreacaLogoD from '../../assets/images/d-logo.png'
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { SERVER_URL } from "../../service/config";
import { loginSchema } from "../../utils/Schemas";
import { setLogin, setPerson } from "../../state";

const initialValuesLogin = {
    username: "",
    password: "",
};

const Login = () => {
    const RememberSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 50,
        height: 30,
        padding: 0,
        marginRight: 10,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            transform: 'translateY(1px)',
            '&.Mui-checked': {
                transform: 'translateX(22px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === "dark" ? '#2ECA45' : '#00CDE1',
                    opacity: 1,
                    border: 0,
                    // ...theme.applyStyles('dark', {
                    //     backgroundColor: '#2ECA45',
                    // }),
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.mode === "dark" ? theme.palette.grey[600] : theme.palette.grey[100],
                // ...theme.applyStyles('dark', {
                //     color: theme.palette.grey[600],
                // }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === "dark" ? 0.3 : 0.7,
                // ...theme.applyStyles('dark', {
                //     opacity: 0.3,
                // }),
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 23,
            height: 23,
        },
        '& .MuiSwitch-track': {
            borderRadius: 32 / 2,
            backgroundColor: theme.palette.mode === "dark" ? '#39393D' : '#E9E9EA',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
            // ...theme.applyStyles('dark', {
            //     backgroundColor: '#39393D',
            // }),
        },
    }));

    const { palette } = useTheme();
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const login = async (values, onSubmitProps) => {
        setLoading(true);

        try {
            const { username, password } = values;

            const loggedInResponse = await fetch(SERVER_URL + "login", {
                method: "POST",
                body: JSON.stringify({ username, password }),
                headers: { "Content-Type": "application/json" },
            });

            if (loggedInResponse.ok) {
                const loggedIn = await loggedInResponse.json();

                dispatch(
                    setLogin({
                        user: loggedIn.user,
                        token: loggedIn.token,
                    })
                );
                console.log("loggedIn.user", loggedIn.user);
                dispatch(setPerson({ person: loggedIn.user }));
                toast.success(`Welcome back ${loggedIn.user.username}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                navigate("/");
            } else {
                toast.error("Incorret Credentials !!!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }

            onSubmitProps.resetForm();
        } catch (err) {
            console.error(err);
        }
        setLoading(false);
    };

    const handleFormSubmit = async (values, onSubmitProps) => {
        await login(values, onSubmitProps);
    };

    return (
        <Box className={style.LoginMain}>
            <Box className={style.containerBox}>
                <Box className={style.left}>
                    <Box className={style.toprow}>
                        <img
                            src={dreacaLogo}
                            alt="Logo"
                        />
                        <img
                            src={dreacaLogoD}
                            alt="Logo"
                        />
                    </Box>
                    <img
                        className={style.mainLogo}
                        src={dreacaLogoD}
                        alt="Logo"
                    />
                    <Typography variant="h5" className={style.coppyRight} component="h5">© 2023-2024 Dreaca. All rights reserved</Typography>
                </Box>
                <Box className={style.right}>
                    <ToastContainer />
                    <Box className={style.form}>
                        <Formik
                            initialValues={initialValuesLogin}
                            validationSchema={loginSchema}
                            onSubmit={handleFormSubmit}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                resetForm,
                            }) => (
                                <form onSubmit={handleSubmit} id="authForm">

                                    <Typography variant="h1" className={style.title} component="h1">Nice to see you again</Typography>
                                    <Typography variant="h5" component='h5' align="left" className={style.label}>
                                        Login
                                    </Typography>

                                    <TextField
                                        fullWidth
                                        placeholder="Username"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            handleChange(e);
                                        }}
                                        value={values.username}
                                        name="username"
                                        error={
                                            Boolean(touched.username) && Boolean(errors.username)
                                        }
                                        helperText={touched.username && errors.username}
                                    />
                                    <Typography variant="h5" component='h5' align="left" className={style.label}>
                                        Password
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        placeholder="Password"
                                        type="password"
                                        variant="outlined"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.password}
                                        name="password"
                                        error={
                                            Boolean(touched.password) && Boolean(errors.password)
                                        }
                                        helperText={touched.password && errors.password}
                                    />
                                    <Box display="flex" alignItems="center" justifyContent="space-between" my={2}>
                                        <FormControlLabel
                                            style={{ marginLeft: '0', marginRight: '0' }}
                                            control={<RememberSwitch />}
                                            label="Remember me"
                                        />
                                        <Link href="#" variant="body2" className={style.link}>
                                            Forgot password?
                                        </Link>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                        <Button
                            form="authForm"
                            fullWidth
                            variant="outlined"
                            type="submit"
                            disabled={loading}
                            md={{
                                padding: '16px 12px',
                            }}
                            sx={{
                                // width: '116px',
                                // height: '34px',
                                padding: '10px 12px',
                                borderRadius: '16px',
                                borderColor: '#00CDE1',
                                bgcolor: '#00CDE1',
                                ':hover': {
                                    borderColor: '#00CDE1',
                                },
                            }}
                        >
                            {loading ? (
                                <CircularProgress
                                    sx={{
                                        color: palette.neutral.dark,
                                    }}
                                    size={22}
                                />
                            ) :
                                (<Typography
                                    sx={{
                                        fontSize: '24px',
                                        fontWeight: '700',
                                        color: '#fff',
                                        textTransform: 'none', // Prevents text from being converted to uppercase  
                                    }}
                                >
                                    Sign in
                                </Typography>)
                            }
                        </Button>
                        <Typography variant="h3" component='h3' className={style.textCaption}>
                            Don’t have an account?{' '}
                            <Link href="#" className={style.link}>
                                Contact Us
                            </Link>
                        </Typography>
                        {/* </form>
                    )}
                    </Formik> */}
                    </Box>
                    <Typography variant="h4" component='h4' className={style.textCaption}>
                        Only clubs have dedicated accounts on Dreaca, giving them exclusive tools to manage events, connect
                        with sponsors, and track performance. Other users can freely explore the platform, discovering club
                        activities, upcoming events, and engaging content in our{' '}
                        <Link href="#" className={style.link}>
                            Explore Section
                        </Link>.
                    </Typography>
                </Box>
            </Box>

        </Box>
    );
};

export default Login;