import { Box, Button, ButtonGroup, Container, Divider, Typography } from '@mui/material';
import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import style from './style.module.scss'
import dreacaLogo from '../../assets/images/logo.png'
import dreacaLogoD from '../../assets/images/d-logo.png'
import { setIsPublicMode, setLogout } from '../../state';

const Splash = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [activeBtn, setActiveBtn] = useState('explore');
    return (
        <Box className={style.splashMain}>
            <Container maxWidth="lg" className={style.containerBox}>
                <Box className={style.toprow}>
                    <img
                        src={dreacaLogo}
                        alt="Logo"
                    />
                    <img
                        src={dreacaLogoD}
                        alt="Logo"
                    />
                </Box>
                <Box className={style.contentText}>
                    <Typography variant="h5" className={style.coppyRight} component="h5">Dreaca is your dynamic social platform, designed for university clubs to empower you with centralized management, sponsor matching, gamified engagement, and enhanced visibility, all to boost your impact across the community.</Typography>
                    <Box className={style.btnBox}>
                        <Typography onClick={() => {setActiveBtn('explore'); dispatch(setIsPublicMode(true)); setLogout(); navigate('/')}} variant="button" className={`${style.btn} ${(activeBtn === 'explore' || activeBtn === '') && style.active}`} component="button">Explore</Typography>
                        <Typography onClick={() => {setActiveBtn('login'); navigate('/login')}} variant="button" className={`${style.btn} ${activeBtn === 'login' && style.active}`} component="button">Association</Typography>
                    </Box>
                </Box>
                <Typography variant="h5" className={style.coppyRight} component="h5">© 2023-2024 Dreaca. All rights reserved</Typography>
            </Container>
        </Box>
    );
};

export default Splash;