import { SERVER_URL } from "../service/config";
export const getUsers = async (page) => {
    console.log('pageeeee', page);
    try {
        const response = await fetch(`${SERVER_URL}u?page=${page}&pageSize=6`, {
            method: "GET",
          });
    
          if (response.ok) {
            const usersData = await response.json();
            return usersData;
          } else {
            console.error("User doesn't exist");
          }
    } catch (err) {
        console.error(err);
    }
}